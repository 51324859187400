import React, { Component } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import SkinningTip from '../images/skinning1.gif';
import FBodyMount from '../images/skinning2.gif';
import ResponsivePlayer from '../components/ResponsivePlayer';
class TrophyFieldCare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: 'https://vimeo.com/16071347',
    };
    this.onVideoClick = this.onVideoClick.bind(this);
  }
  onVideoClick(event, url) {
    event.preventDefault();
    this.setState({
      url: url,
    });
  }
  render() {
    return (
      <Container className="full-width-transparent clearfix content-inner">
        <Row>
          <h1>Field Care Advice</h1>
          <h2>
            Taxidermist Tony Breedlove speaks about Field Care for your trophy
          </h2>
        </Row>
        <Row>
          <ResponsivePlayer url={this.state.url} />
        </Row>
        <Row className="bottom-breaker ">
          <div id="player_playlist">
            <ul id="player_playlist_list">
              <li class="">
                <a
                  href="https://vimeo.com/16071347"
                  onClick={(event) =>
                    this.onVideoClick(event, 'https://vimeo.com/16071347')
                  }
                  title="Field Care Advice - Part 1"
                >
                  <span class="shade"></span>
                  <span class="border"></span>
                  <img
                    src="https://i.vimeocdn.com/video/97859843-a5696bad38011bac121f58eae51107f5c060c939ee896d855b07d57f8c50dd8a-d_100x75"
                    alt=""
                  />
                  <span class="title">Field Care Advice - Part 1</span>
                  <span class="byline">
                    from <b>bigcountrystudios</b>{' '}
                    <time datetime="2010-10-21 17:00:19">11 years ago</time>
                  </span>
                  <span class="desc">
                    Taxidermist Tony Breedlove speaks about Field Care...
                  </span>
                  <span class="duration">2:48</span>
                </a>
              </li>
              <li class="">
                <a
                  href="https://vimeo.com/16071520"
                  onClick={(event) =>
                    this.onVideoClick(event, 'https://vimeo.com/16071520')
                  }
                  title="Field Care Advice - Part 2"
                >
                  <span class="shade"></span>
                  <span class="border"></span>
                  <img
                    src="https://i.vimeocdn.com/video/97862632-230e783dcc921ac9d07693a52040568b37ae1605ffd5a370d3e5284e72edb7ed-d_100x75"
                    alt=""
                  />
                  <span class="title">Field Care Advice - Part 2</span>
                  <span class="byline">
                    from <b>bigcountrystudios</b>{' '}
                    <time datetime="2010-10-21 17:05:25">11 years ago</time>
                  </span>
                  <span class="desc">
                    Taxidermist Tony Breedlove speaks about Field Care...
                  </span>
                  <span class="duration">9:09</span>
                </a>
              </li>
              <li class="">
                <a
                  href="https://vimeo.com/16072108"
                  onClick={(event) =>
                    this.onVideoClick(event, 'https://vimeo.com/16072108')
                  }
                  title="Wildlife Art Creations"
                >
                  <span class="shade"></span>
                  <span class="border"></span>
                  <img
                    src="https://i.vimeocdn.com/video/97863714-ac5412d879c04e2adc7d80f5f471c594744e514984a7f567ba1968b2347c60b9-d_100x75"
                    alt=""
                  />
                  <span class="title">Wildlife Art Creations</span>
                  <span class="byline">
                    from <b>bigcountrystudios</b>{' '}
                    <time datetime="2010-10-21 17:23:58">11 years ago</time>
                  </span>
                  <span class="desc">
                    Taxidermist Tony Breedlove speaks about Taxidermis...
                  </span>
                  <span class="duration">1:33</span>
                </a>
              </li>
              <li class="">
                <a
                  href="https://vimeo.com/60049521"
                  onClick={(event) =>
                    this.onVideoClick(event, 'https://vimeo.com/60049521')
                  }
                  title="Insect Damage Repair"
                >
                  <span class="shade"></span>
                  <span class="border"></span>
                  <img
                    src="https://i.vimeocdn.com/video/417533893-19119b54cf6ce2244cfa76fc966ab90168a0527d58cfe2c478853d14115380f0-d_100x75"
                    alt=""
                  />
                  <span class="title">Insect Damage Repair</span>
                  <span class="byline">
                    from <b>bigcountrystudios</b>{' '}
                    <time datetime="2013-02-19 22:00:25">9 years ago</time>
                  </span>
                  <span class="desc">
                    Taxidermist Tony Breedlove talks about repairing r...
                  </span>
                  <span class="duration">20:49</span>
                </a>
              </li>
              <li class=" selected">
                <a
                  href="https://vimeo.com/60049522"
                  onClick={(event) =>
                    this.onVideoClick(event, 'https://vimeo.com/60049522')
                  }
                  title="Wildlife Art Creations Gallery Slideshow"
                >
                  <span class="shade"></span>
                  <span class="border"></span>
                  <img
                    src="https://i.vimeocdn.com/video/417535484-c5e9dc1d51e28c41632a312d04ac6608ebc7074e7ec7387caffb4219530c7969-d_100x75"
                    alt=""
                  />
                  <span class="title">Wildlife Art Creations Gallery...</span>
                  <span class="byline">
                    from <b>bigcountrystudios</b>{' '}
                    <time datetime="2013-02-19 22:00:25">9 years ago</time>
                  </span>
                  <span class="desc"></span>
                  <span class="duration">21:00</span>
                </a>
              </li>
            </ul>
          </div>
        </Row>
        <Row className="bottom-breaker">
          <h2>Basic Field Care Notes</h2>
          <ul className="tick">
            <li>
              Always check local, state, and federal game and fish regulations
              for all species. Some have size limits and others require special
              permits for possession.
            </li>
          </ul>
          <p>
            We offer the following information to aid in the preparation of your
            trophy for transport. We are available for further consultation and
            pre-hunt advice to ensure a quality product for you. Animal skins
            are perishable and must be treated as such. From the moment a trophy
            is taken the natural process of decomposition begins. You, and we,
            are fighting that fact every moment we are working on your mount.
            Bacteria are immediately working to break down the hide. You must
            work as fast and as cleanly as possible to prepare the skin and
            horns/antlers for transport to us.
          </p>
          <p>
            Remember heat and bacteria are of big concern. Field care must be
            taken seriously to ensure good mountable specimen. We encourage
            clients to know and understand these factors to aid in the goal of a
            quality mount.
          </p>
          <p>
            Wildlife Art Creations, Inc. cannot be responsible for the care of
            skins and horns/antlers before they reach our facility. Skins have
            often traveled thousands of miles over many weeks to reach us.
            Improper handling in the beginning can result in a skin that is not
            mountable.
          </p>
        </Row>
        <Row className="bottom-breaker">
          <h2>Large mammals</h2>

          <ul className="tick">
            <li>Never cut the throat</li>
          </ul>
          <p>
            Large mammals should be field dressed as soon as possible. Limit the
            cavity incision to only as large as is absolutely necessary to
            remove the entrails. Do not cut past the &#8220;hard brisket&#8221;
            up into the chest. Try to keep blood and other body fluids off of
            the fur. If water is available, wash out the cavity. Prop the cavity
            open to allow heat to dissipate. Refer to our instructions for
            skinning and hide preserving. Be aware of the type of mount you have
            in mind in advance of skinning. Each type of mount has very
            different methods for skinning.
          </p>
        </Row>
        <Row className="bottom-breaker">
          <h2>Small Mammals</h2>
          <p>
            Small mammals such as bobcat, fox, squirrel, etc., should be left
            whole. There is no need to field dress these animals. Allow the
            specimen to cool, then wrap in plastic. It is best to take these
            trophies to your taxidermist fresh. If necessary to freeze, wrap in
            two layers of plastic and lay flat, and &#8220;uncurled&#8221;. To
            avoid damage to small feet, thin legs, and around the eyes, ears,
            and mouth don’t leave in freezer more than a few months.
          </p>
        </Row>
        <Row className="bottom-breaker">
          <h3>Birds</h3>
          <p>
            Birds retain heat for a very long time. Their feathers insulate so
            well body heat cannot escape. Birds spoil very quickly from the
            inside out. When hunting ducks, geese, dove, or quail never pile the
            birds together in one bag. Body heat will combine to cause damage to
            your mountable birds. Keep mounting prospects separate, cool, clean,
            and dry. Fluff the feathers to promote cooling. Small birds such as
            dove, quail, and ducks may be left whole. Larger birds (turkey,
            geese) should be field dressed by opening the cavity as soon as
            possible. Start at the &#8220;vent&#8221;, cut from the inside out,
            toward the breast. Only open 1½ -2 inches. Remove the entrails then
            prop the cavity open to allow body heat to escape. Be very careful
            not to soak the feathers with blood or body fluid. If necessary wipe
            fluids from feathers immediately with a clean cloth or paper towel.
            This will reduce staining of the feathers. Spread the wings and
            fluff the feathers to allow further cooling. If you are unable to
            take your birds directly to the taxidermist, they must be frozen
            after cooling. Smooth feathers, tuck head under a wing and wrap in
            at least two layers of plastic. Lay flat in the freezer. Limit
            freezer time to a few months. Freezer burn to feet and eyes will
            occur and is irreversible.
          </p>
        </Row>
        <Row className="bottom-breaker">
          <h3>Fish</h3>
          <ul className="tick">
            <li> Do not wash off the natural &#8220;slime&#8221; coating.</li>
          </ul>
          <p>
            Fresh water fish should be left whole. Keep them cold. If necessary
            to freeze, place wet fish into a plastic bag being certain the fins
            are smoothed as flat to the body as possible. Extended, brittle fins
            are very breakable. Wrap the bag around the fish, wrap the bag with
            a damp towel, then place into another plastic bag. Lay flat to
            freeze. Never wrap fish in newspaper, this will promote freezer
            burn.
          </p>
        </Row>
        <Row className="bottom-breaker">
          <h3>Reptiles</h3>
          <p>
            Reptiles (snakes, lizards, etc.) should be wrapped in plastic and
            frozen whole.
          </p>
        </Row>
        <Row className="bottom-breaker rowWithImg">
          <img class="img-right" src={SkinningTip} alt="Shoulder Mount" />
          <h2>Skinning Tips</h2>

          <p>
            Incisions are very hard to hide, especially on trophies with short
            or course hair. Incisions on legs and the back of the neck are
            extremely hard to repair, even for the most experienced taxidermist.
            Unnecessary incisions also add time to completing the mount, adding
            to your cost.
          </p>
          <h3>Shoulder, Half, &amp; Pedestal Mounts</h3>
          <p>
            When skinning your trophy for half, shoulder, or pedestal mounts be
            sure the field dressing incision does not pass the point of where
            the hard brisket begins. You don’t want a seam in the chest of your
            mount.{' '}
          </p>
          <p>
            Make incisions as indicated on diagram 1. The back hide may be
            removed just in front of the rear flanks, as indicated by the
            horizontal line. The hide or cape can be rolled down, like removing
            a sock As you reach the shoulders continue rolling the hide over the
            upper leg and cut the leg off on the inside just above the knee,
            leaving the lower leg intact. Continue inverting the hide down the
            neck to the jaw line. Sever the head from the neck on the inside as
            close to the jaw as possible, leaving no incisions from the brisket
            forward. Cool the hide. Keep cool and dry while transporting to your
            taxidermist. If necessary to freeze <em>fold</em> hide from the back
            forward and place in plastic.{' '}
          </p>

          <h3>Most Life Sized Mounts</h3>
          <p>
            <em>
              Talk to Tony to determine which life sized skinning method is best
              for the mount you have in mind.
            </em>
          </p>
          <p>
            Eliminating incisions can be one of the greatest enhancements to
            your mount.{' '}
            <em>Be sure to obtain the proper measurements before skinning!</em>{' '}
            Refer to the measurement information and chart provided. For the
            best results in preparing your trophy for a life sized mount follow
            these simple instructions.
          </p>
          <p>
            <img class="img-left" src={FBodyMount} alt="Full Body Mount" />
            Lay the animal on its side. Begin your incision a few inches below
            the base of the skull following the backbone as indicated by
            &#8220;A&#8221; on diagram 2. Stop the incision a few inches above
            where the tail joins the body. Separate the skin from the body on
            both sides. Clip the tail from the body, leaving the bone in the
            tail for now. Continue inverting the skin down the rear legs,
            similar to the way you would remove your sock. Clip the bone just
            below the knee, leaving the lower leg and foot intact. Follow the
            same procedure for the front legs. Continue inverting the hide down
            the neck to the jaw line. Sever the head from the neck as close to
            the jaw line as possible. Cool the hide. Keep cool and dry while
            transporting to your taxidermist. If necessary to freeze allow heat
            to dissipate from hide then <em>fold</em> the hide from back to head
            and place in plastic.
          </p>
        </Row>
      </Container>
    );
  }
}
export default TrophyFieldCare;
