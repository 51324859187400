import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';

const Contact = () => {
  return (
    <Container className="full-width-transparent clearfix content-Contact">
      <Row>
        <h1>Contact Us</h1>
        <h2>Have Questions? We would love to hear from you</h2>
        <h3>Send us a message</h3>
      </Row>
      <Container className="plain-black clearfix">
        <Row className="bottom-breaker">
          <Col>
            <h3>Phone</h3>
          </Col>
          <Col className="align-right">
            <p>+1(352)475-3159</p>
            <a href="tel:352-475-3159">CLICK TO CALL</a>
          </Col>
        </Row>
        <Row className="bottom-breaker ">
          <Col>
            <h3>Email</h3>
          </Col>
          <Col className="align-right">
            <a href="mailto:tony-leslie@comcast.net">Email Tony and Leslie</a>
          </Col>
        </Row>
        <Row className="bottom-breaker ">
          <Col>
            <h3>Location</h3>
          </Col>
          <Col className="align-right">
            <ul>
              <li>Wildlife Art Creations</li>
              <li>3188 SE County Road 21B</li>
              <li>Melrose, FL 32666</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <div className="container-fluid">
            <div className="map-responsive">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3463.458330991085!2d-82.08169648455328!3d29.764385938702222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e6051ebea56f43%3A0xf96f16e8253190c8!2s3188%20SE%20County%20Rd%2021b%2C%20Melrose%2C%20FL%2032666!5e0!3m2!1sen!2sus!4v1647715074175!5m2!1sen!2sus"
                width="600"
                height="450"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </Row>
      </Container>
    </Container>
  );
};
export default Contact;
