import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import FFnGLogo from '../images/links/FFnGLogo.png';
import NRA from '../images/links/NRA.png';
import SCI from '../images/links/SCI.png';
import GWRD from '../images/links/GWRD.png';
const Links = () => {
  return (
    <Container className="full-width-transparent clearfix content-inner">
      <Row>
        <h1>Links</h1>
        <h2>Partners and helpful information</h2>
      </Row>
      <Row className="bottom-breaker align-center">
        <Col>
          <a href="http://myfwc.com/" target="_blank" rel="noreferrer">
            <img src={FFnGLogo} alt="Florida Fish and Wildlife link" />
          </a>
        </Col>
        <Col>
          <a href="http://home.nra.org/" target="_blank" rel="noreferrer">
            <img src={NRA} alt="Florida Fish and Wildlife link" />
          </a>
        </Col>
      </Row>
      <Row className="bottom-breaker align-center">
        <Col>
          <a
            href="http://www.scifirstforhunters.org/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={SCI} alt="Florida Fish and Wildlife link" />
          </a>
        </Col>
        <Col>
          <a
            href="http://vanguardoutfittersllc.com/"
            target="_blank"
            rel="noreferrer"
          >
            Vanguard Outfitters - Firearms and custom loaded ammo
          </a>
          <br />

          <a href="http://www.smooth-on.com/" target="_blank" rel="noreferrer">
            Smooth On
          </a>
          <br />
          <a
            href="http://www.georgiataxidermistassociation.org/"
            target="_blank"
            rel="noreferrer"
          >
            Georgia Taxidermist Association
          </a>
          <br />
          <a
            href="http://www.myfsta.com/index.html"
            target="_blank"
            rel="noreferrer"
          >
            Florida State Taxidermists Association
          </a>
          <br />
          <a
            href="http://www.nationaltaxidermists.com/"
            target="_blank"
            rel="noreferrer"
          >
            National Taxidermists Association
          </a>
          <br />
          <a
            href="http://www.faunaandflora.com/trophies/index.html"
            target="_blank"
            rel="noreferrer"
          >
            Fauna and Flora - Customs Broker
          </a>
          <br />
          <a
            href="http://www.breakthroughmagazine.com/"
            target="_blank"
            rel="noreferrer"
          >
            Breakthrough Magazine
          </a>
        </Col>
      </Row>
      <Row className="bottom-breaker align-center">
        <Col>
          <a
            href="http://www.georgiawildlife.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={GWRD} alt="GA Wildlife Resources" />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default Links;
