import React, { Component } from 'react';
import $ from 'jquery';
import { Row, Container, Col } from 'react-bootstrap';
import '../Home.css';
export default class Home extends Component {
  render() {
    return (
      <div className="content-inner homeContent">
        <Container fluid className="left-note-block ">
          <Row>
            <Col className="homePadding">
              <h1>Welcome</h1>
            </Col>
          </Row>
          <Row>
            <Col className="homePadding">
              <h2>
                <a href="#">Wildlife Art Creations</a>
              </h2>
            </Col>
          </Row>
          <Row>
            <Col className="homePadding">
              <h2>
                <a href="https://content.govdelivery.com/accounts/FLFFWCC/bulletins/32760bc">
                  Alert: <br />
                  Any Whitetail Deer, Mule Deer, Elk are to be handled properly
                  before legally being brought into Florida
                </a>
              </h2>
            </Col>
          </Row>
          <Row>
            <Col className="homePadding">
              <h2>
                <a href="https://content.govdelivery.com/accounts/FLFFWCC/bulletins/304f5f5">
                  CWD Detected in Alabama: Click for more details!
                </a>
              </h2>
            </Col>
          </Row>
          <Row>
            <Col className="homePadding">
              Wildlife Art Creations by Tony and Leslie Breedlove is a full
              service custom Taxidermist located in Melrose, Florida. Since
              1984, we have specialized in preserving your trophies with the
              latest techniques and forms in the business. Our work is proudly
              displayed at many local establishments and private collections
              throughout Northeast Florida. If you are looking for a reliable
              Taxidermist known for quality, customized, wildlife art, look no
              further.
            </Col>
          </Row>
          <Row>
            <Col className="homePadding">
              Thanks for visiting our site. Be sure to check out our{' '}
              <a href="gallery-recent.html">Photo Galleries</a> and{' '}
              <a href="trophy-field-care.html">video guides on Field Care</a>{' '}
              for your trophy.
            </Col>
          </Row>
          <Row>
            <Col>
              <div id="quotes">
                <blockquote>
                  <p>
                    {' '}
                    I personally would not recommend any other taxidermist. I
                    mean this with all sincerity. I drive almost six hours to
                    get there.
                  </p>
                  <cite>Adam M. Haynes - South Florida</cite>{' '}
                </blockquote>
                <blockquote>
                  <p>
                    If you’re looking for a trophy that will last a lifetime,
                    don’t shortchange yourself on the front end by choosing
                    less. Wildlife Art Creations is my exclusive taxidermist now
                    that I know and see the difference! 
                  </p>
                  <cite>Gregg Eason</cite>{' '}
                </blockquote>
                <blockquote>
                  <p>
                    Wildlife Art Creations has mastered the art of taxidermy.
                    Their work makes your trophy look so alive in every detail.{' '}
                  </p>
                  <cite> Major Harding</cite>{' '}
                </blockquote>
                <blockquote>
                  <p>
                    Tony and Leslie's taxidermy is unsurpassed. I have used them
                    exclusively for over twenty years. The trophies are wildlife
                    art, life-like and beautiful. They are the greatest and are
                    good people. 
                  </p>
                  <cite> Gordon Vines</cite>{' '}
                </blockquote>
                <blockquote>
                  <p>
                    I have had several whitetails mounted over the years, of
                    which Tony did one.  Hands down his is the most realistic,
                    life-like mount of the bunch.
                  </p>
                  <cite> James A. Cranford, CCIM</cite>{' '}
                </blockquote>
                <blockquote>
                  <p>
                    Y'all certainly made at least one person very happy today.
                    Thank you!
                  </p>
                  <cite> John Allen</cite>{' '}
                </blockquote>
                <blockquote>
                  <p>
                    Many thanks for doing such a wonderful job on my deer and
                    moose mounts.   The buck is handsome and fits in well in our
                    study.  [Their] attention to detail and artistic ability to
                    make him come to life is truly impressive. 
                  </p>
                  <cite> Eric Jokela</cite>{' '}
                </blockquote>
                <blockquote>
                  <p>
                    The buck looks fantastic thanks to all of your hard work.
                     The buck, stump and pedestal base all look incredible and
                    are more than I could envision.
                  </p>
                  <cite> Mike Dixon</cite>{' '}
                </blockquote>
                <blockquote>
                  <p>
                    [Their] attention to detail in creating a life-like mount is
                    the reason. They are my only choice for preserving the
                    memories to last a lifetime
                  </p>
                  <cite> Bob Wood</cite>{' '}
                </blockquote>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
