import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import logo from '../images/logo.png';
import { Link } from 'react-router-dom';
const Header = () => {
  return (
    <Navbar id="header-outer" collapseOnSelect expand="lg">
      <Container id="header-inner">
        <Navbar.Brand href="#home">
          <img src={logo} alt="Wild Life Art Creations Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav ">
          <Nav className="me-auto"></Nav>
          <Nav>
            <Nav.Link className="custNavItem" href="/">
              Home
            </Nav.Link>
            <NavDropdown title="Gallery">
              <NavDropdown.Item href="/Gallery?FID=72157630274202560&title=European/Antler Panels">
                European/Antler Panels
              </NavDropdown.Item>
              <NavDropdown title="North America" id="nobg-dropdown">
                <NavDropdown.Item
                  className="button-link"
                  href="/Gallery?FID=72157644022151783&title=Fish"
                >
                  Fish
                </NavDropdown.Item>
                <NavDropdown.Item href="/Gallery?FID=72157630273776714&title=Turkey">
                  Turkey
                </NavDropdown.Item>
                <NavDropdown.Item href="/Gallery?FID=72157636817484943&title=Elk">
                  Elk
                </NavDropdown.Item>
                <NavDropdown.Item href="/Gallery?FID=72157630273690878&title=Bobcats">
                  Bobcats
                </NavDropdown.Item>
                <NavDropdown.Item href="/Gallery?FID=72157630273685392&title=Racoons">
                  Racoons
                </NavDropdown.Item>
                <NavDropdown.Item href="/Gallery?FID=72157630273680502&title=Birds">
                  Birds
                </NavDropdown.Item>
                <NavDropdown.Item href="/Gallery?FID=72157630273667884&title=Bears">
                  Bears
                </NavDropdown.Item>
                <NavDropdown.Item href="/Gallery?FID=72157630272723952&title=Snakes">
                  Snakes
                </NavDropdown.Item>
                <NavDropdown.Item href="/Gallery?FID=72157630272638752&title=Pigs">
                  Pigs
                </NavDropdown.Item>
                <NavDropdown.Item href="/Gallery?FID=72157630272616712&title=Whitetails">
                  Whitetails
                </NavDropdown.Item>
                <NavDropdown.Item href="/Gallery?FID=72157630272590722&title=Gators">
                  Gators
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown.Item href="/Gallery?FID=72157630272602484&title=African Animals">
                Africa
              </NavDropdown.Item>
              <NavDropdown.Item href="/Gallery?FID=72157630273700828&title=New Zealand">
                New Zealand
              </NavDropdown.Item>
              <NavDropdown.Item href="/Gallery?FID=72157630272636366&title=Predators">
                Predators
              </NavDropdown.Item>
              <NavDropdown.Item href="/Gallery?FID=72157630273722656&title=Exotics">
                Exotic
              </NavDropdown.Item>
              <NavDropdown.Item href="/Gallery?FID=72157630272625456&title=Tony and Leslie">
                Tony &amp; Leslie
              </NavDropdown.Item>
              <NavDropdown.Item href="/Videos">Videos</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Services" id="basic-nav-dropdown">
              <NavDropdown.Item href="/TrophyFieldCare">
                Field Care Advice
              </NavDropdown.Item>
              <NavDropdown.Item href="/Extermination">
                Extermination and Restoration
              </NavDropdown.Item>
              <NavDropdown.Item href="/TrophyProblemCheckList">
                Trophy Problem Checklist
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="custNavItem" href="/Links">
              Links
            </Nav.Link>
            <Nav.Link className="custNavItem" href="/Contact">
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
  // return (
  //   <Navbar id="header-outer" collapseOnSelect expand="lg">
  //     <Container id="header-inner">
  //       <Navbar.Brand href="#home">
  //         <img src={logo} alt="Wild Life Art Creations Logo" />
  //       </Navbar.Brand>
  //       <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  //       <Navbar.Collapse id="basic-navbar-nav" className="nav-wrap-inner">
  //         <Nav>
  //           <Nav.Link href="#home">Home</Nav.Link>
  //           <NavDropdown title="Gallery" id="basic-nav-dropdown">
  //             <NavDropdown.Item href="#Gallery/1">
  //               European/Antler Panels
  //             </NavDropdown.Item>
  //             <NavDropdown title="North America" id="basic-nav-dropdown">
  //               <NavDropdown.Item href="#Gallery/2">Fish</NavDropdown.Item>
  //               <NavDropdown.Item href="#Gallery/3">Turkey</NavDropdown.Item>
  //               <NavDropdown.Item href="#Gallery/4">Elk</NavDropdown.Item>
  //               <NavDropdown.Item href="#Gallery/5">Bobcats</NavDropdown.Item>
  //               <NavDropdown.Item href="#Gallery/6">Racoons</NavDropdown.Item>
  //               <NavDropdown.Item href="#Gallery/7">Birds</NavDropdown.Item>
  //               <NavDropdown.Item href="#Gallery/8">Bears</NavDropdown.Item>
  //               <NavDropdown.Item href="#Gallery/9">Snakes</NavDropdown.Item>
  //               <NavDropdown.Item href="#Gallery/10">Pigs</NavDropdown.Item>
  //               <NavDropdown.Item href="#Gallery/11">
  //                 Whitetails
  //               </NavDropdown.Item>
  //               <NavDropdown.Item href="#action/3.9">Gators</NavDropdown.Item>
  //             </NavDropdown>
  //             <NavDropdown.Item href="#action/3.1">Africa</NavDropdown.Item>
  //             <NavDropdown.Item href="#action/3.1">
  //               New Zealand
  //             </NavDropdown.Item>
  //             <NavDropdown.Item href="#action/3.1">Predators</NavDropdown.Item>
  //             <NavDropdown.Item href="#action/3.1">Exotic</NavDropdown.Item>
  //             <NavDropdown.Item href="#action/3.1">
  //               Tony &amp; Leslie
  //             </NavDropdown.Item>
  //             <NavDropdown.Item href="#action/3.1">Videos</NavDropdown.Item>
  //           </NavDropdown>
  //           <NavDropdown title="Services" id="basic-nav-dropdown">
  //             <NavDropdown.Item href="#action/3.1">
  //               Field Care Advice
  //             </NavDropdown.Item>
  //             <NavDropdown.Item href="#action/3.2">
  //               Extermination and Restoration
  //             </NavDropdown.Item>
  //             <NavDropdown.Item href="#action/3.2">
  //               Trophy Problem Checklist
  //             </NavDropdown.Item>
  //           </NavDropdown>
  //           <Nav.Link href="#link">Links</Nav.Link>
  //           <Nav.Link href="#link">Contact Us</Nav.Link>
  //         </Nav>
  //         <Nav>
  //           <Nav.Link href="#deets">More deets</Nav.Link>
  //           <Nav.Link eventKey={2} href="#memes">
  //             Dank memes
  //           </Nav.Link>
  //         </Nav>
  //       </Navbar.Collapse>
  //     </Container>
  //   </Navbar>
  // );
};
export default Header;
