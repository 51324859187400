import React from 'react';
import { Container, Row } from 'react-bootstrap';

const Footer = () => {
  return (
    <Container id="footer">
      <div className="footer-inside clearfix">
        <div className="foot-left-col">
          Copyright &copy; Wildlife Art Creations (352)475-3159 &#8226; website
          by <a href="http://www.bigcountrystudios.com">bigcountry studios</a>
        </div>

        <div class="foot-right-col">
          <ul class="footer-menu">
            <li>
              <a id="social-pop-out-trigger" href="#">
                <span class="up-ico">Connect with us</span>
              </a>
            </li>

            <li class="top">
              <a href="#top" class="scroll-top">
                <span class="up-ico">Top</span>
              </a>
            </li>
          </ul>

          <div class="footer-pop-out-box social-pop-out-box">
            <div class="clearfix">
              <div class="one-icon-wrap">
                <a
                  href="https://www.facebook.com/pages/Wildlife-Art-Creations/204686022903483?fref=ts"
                  target="_blank"
                >
                  <img
                    src="images/social-icons/facebook.png"
                    width="32"
                    height="32"
                    alt="Facebook"
                  />
                </a>
              </div>
              <div class="one-icon-wrap">
                <a
                  href="http://www.flickr.com/photos/wildlifeartcreations/sets/"
                  target="_blank"
                >
                  <img
                    src="images/social-icons/flickr.png"
                    width="32"
                    height="32"
                    alt="Flickr"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Footer;
