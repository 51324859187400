import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
const TrophyProblemCheckList = () => {
  return (
    <Container className="full-width-transparent clearfix content-inner">
      <Row id="tpcl">
        <h1>Trophy Problem Checklist</h1>
        <h2>Taken from the U.S. Fish &amp; Wildlife Service 2010 p.28</h2>
        <h4 class="uppercase">Tags</h4>{' '}
        <p>
          Must be 1) permanently attached 2) through a hole. Ear, eye, mouth,
          nose, bullet holes are okay, but not around the leg above the foot. 
          Tag number must match that on the permit.
        </p>
        <h4 class="uppercase">Permit expiration</h4>{' '}
        <p>
          Get a faxed copy of the import permit before exporting. Do not ship an
          Appendix I species without seeing a copy of the import permit to be
          sure it will not expire before the shipment arrives.  Examine the
          export permit for expiration date and look for date errors.
        </p>
        <h4 class="uppercase">Export permit</h4>{' '}
        <p>
          Examine for errors of name of permittee and name and number of
          species, signature and seal by CITES designated officers.
        </p>
        <h4 class="uppercase">Validation</h4>{' '}
        <p>
          Make sure section 14 of export permit is fully completed, i.e. all
          parts itemized, signed and sealed by designated CITES officer before
          the final step of shipment.
        </p>
        <h4 class="uppercase">Purpose code</h4>{' '}
        <p>
          If crafted or worked item of trophy parts (feet, tail swish, bracelet,
          scrimshawed tusks, boots, gun cases, clothing, etc.) export permit
          must be coded &ldquo;P&rdquo; for person instead of &ldquo;H&rdquo;
          for hunting trophy.  If part of an elephant or rhino trophy on
          Appendix II, it must have an Appendix I import permit (Form 3-200-37)
          because it&rsquo;s not treated as a trophy.  Only trophy trade is on
          Appendix II, not trophies converted into &ldquo;personal&rdquo; items.
        </p>
        <h4 class="uppercase">Valuation</h4>{' '}
        <p>
          Understatement of value is cause of excessive seizures, i.e.
          forfeiture of $50,000 trophies for a $500 offense.  A true
          representative value should be used, not understatement.  Prorated
          cost of acquisition (cost of the hunt) is best or insurance value. 
          Note: trophies are not taxed upon entry into the U.S. but they most
          certainly are seized.  The exporter should use full value from the
          get-go as import brokers carry it over onto their declarations.
        </p>
        <h4 class="uppercase">In Transit</h4>{' '}
        <p>
          Transfer through intermediate countries must be immediate, without
          delay.  A hunter traveling with his trophy cannot layover in an
          intermediate country without appropriate import and re-exports permits
          from that country.
        </p>
        <h4 class="uppercase">Post-Shipment Corrections</h4>{' '}
        <p>
          Exporting authorities must immediately contact and confer with U.S.
          authorities before issuing a retrospective permit or replacement
          permit, not months later or after issuing a new permit.  Retrospective
          and replacement permits must be issued immediately, not weeks or
          months later.  The importing agent must set corrective action in
          motion immediately and use a true value for the trophy on the 3-177
          Declaration entry form rather than carry over as the value the export
          fee or some other incorrect value from the export documents. {' '}
        </p>
        <h4 class="uppercase">Re-shipment</h4>{' '}
        <p>
          Send trophies back whenever you can else it is treated as illegal to
          possess contraband like stolen goods or illegal drugs without any
          protectable interest.
        </p>
        <h4 class="uppercase">Re-shipment import permits</h4>{' '}
        <p>
          When trophies are returned to the exporting country and re-shipped,
          new original import permits are required.
        </p>
      </Row>
    </Container>
  );
};
export default TrophyProblemCheckList;
