import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Gallery from './components/Gallery';
import Links from './components/Links';
import Contact from './components/Contact';
import TrophyFieldCare from './components/TrophyFieldCare';
import Extermination from './components/Extermination';
import TrophyProblemCheckList from './components/TrophyProblemCheckList';
import Videos from './components/Videos';
import { Routes, Route } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="links" element={<Links />} />
        <Route path="contact" element={<Contact />} />
        <Route path="trophyFieldCare" element={<TrophyFieldCare />} />
        <Route path="Extermination" element={<Extermination />} />
        <Route
          path="TrophyProblemCheckList"
          element={<TrophyProblemCheckList />}
        />
        <Route path="Videos" element={<Videos />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
