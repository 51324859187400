import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import 'react-gallery-carousel/dist/index.css';
const Gallery = () => {
  const search = useLocation().search;
  const FlickerID = new URLSearchParams(search).get('FID');
  const Title = new URLSearchParams(search).get('title');
  const flickerSrc = `https://flickrembed.com/cms_embed.php?source=flickr&layout=responsive&input=${FlickerID}&sort=0&by=album&theme=default&scale=fit&speed=3000&limit=10&skin=default&autoplay=true`;
  return (
    <Container className="content-inner">
      <Row className="transparent-header">
        <h1>{Title}</h1>
      </Row>
      <Row className="plain-black clearfix">
        <iframe
          className="EAP"
          src={flickerSrc}
          scrolling="no"
          frameborder="0"
          allowFullScreen={true}
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        >
          <p>
            Powered by{' '}
            <a href="https://themesort.com">themesort WordPress themes</a> here
          </p>
          <small>
            Powered by <a href="https://flickrembed.com">flickr embed</a>.
          </small>
        </iframe>
      </Row>
      <Row></Row>
    </Container>
  );
};

export default Gallery;
