import React from 'react';
import Carousel from 'react-gallery-carousel';
import { Row, Container, Col } from 'react-bootstrap';
import img1 from '../images/TrophyRepair1.jpg';
import img2 from '../images/TrophyRepair2.jpg';
import img3 from '../images/TrophyRepair3.jpg';
import ResponsivePlayer from '../components/ResponsivePlayer';
const images = [img1, img2, img3].map((number) => ({
  src: number,
}));
const Extermination = () => {
  return (
    <Container className="full-width-transparent clearfix content-inner">
      <Row>
        <h1>Extermination and Restoration for Taxidermy</h1>
        <h2>Another service provided by Wildlife Art Creations</h2>
      </Row>
      <Row id="extCarousel" className="bottom-breaker">
        <Col>
          <div id="extCarouselContainer">
            <Carousel
              images={images}
              canAutoPlay="true"
              autoPlayInterval="5000"
              isLoop="true"
              isAutoPlaying="true"
              hasLeftButton="false"
              hasRightButton="false"
              hasMediaButton="false"
              hasSizeButton="false"
              hasIndexBoard="false"
              hasThumbnails="false"
            />
          </div>
        </Col>
        <Col>
          <h2>Repair Insect Damage </h2>
          <p>
            The insects that infest taxidermy are commonly found in the
            environment around us. Taxidermy simply provides an appealing home
            for the insects to exist. Most infestations have little to do with
            the quality of the taxidermy work, but is more dependent on exposure
            to the infesting insects that enter the taxidermy from the outdoors,
            or can be brought indoors on clothing, furniture, textiles and rugs.
            Typically the first indication would be loose hair or hair loss.
            This is usually combined with insect debris in and around the
            taxidermy. Infestation is usually noticeable around horn, antler,
            hoof and claw areas and between the taxidermy and hard surfaces like
            walls and habitat. View Tony's <a href="#video">Insect Repair</a>{' '}
            Video below.
          </p>
          <ul className="arrow">
            <li>
              Are you finding insect damage on your priceless hunting trophies?
            </li>
            <li>
              Are you finding insect parts or material that looks like sawdust
              in or around your mounts?
            </li>
            <li>
              Do you know there is a naturally based solution to stop insect
              damage?
            </li>
            <li>
              Does your man cave of Taxidermy need some cleaning, renewal and
              protection?
            </li>
          </ul>
          <div>
            <ResponsivePlayer url="https://player.vimeo.com/video/60049521?portrait=0&color=333" />
            {/* <ReactPlayer
              url="https://player.vimeo.com/video/60049521?portrait=0&color=333"
              controls="true"
              playing="false"
              width="100%"
              height="100%"
            /> */}
          </div>

          <p>
            We offer services for all your needs in exterminating products,
            mount protection and prevention products. These treatments kill
            insects within seconds of direct contact and generally protect
            taxidermy for a year or more. The product will kill indirectly but,
            must be wrapped in plastic to contain the vapors in the taxidermy
            for at least four hours to kill 100% of the insects (regardless of
            life-cycle stage).{' '}
          </p>

          <br />
          <h2>Mount Protector</h2>
          <ul className="arrow">
            <li>
              The best insect deterrent, cleaner and natural shine products
              available
            </li>
            <li>Completely compatible with ears and eyes</li>
            <li>
              All chemicals used leave a pleasant odor and specifically designed
              for use on Taxidermy
            </li>
          </ul>
          <h2>Horn Repair</h2>
          <p>
            Wildlife Art Creations offers trophy repair for taxidermy that has
            fallen from its former glory. Broken and/or lost Horns and Antlers
            can be fabricated, reattached, and blended with amazing accuracy to
            original condition. Split ear tips can be repaired to again look and
            feel as they once did. Faded hides, horns and antlers can be
            restored to their original coloration with a natural, life-like
            shine that will restore their majestic beauty.
          </p>
        </Col>
      </Row>
      <Row></Row>
    </Container>
  );
};
export default Extermination;
