import React, { Component } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import ResponsivePlayer from '../components/ResponsivePlayer';
class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: 'https://vimeo.com/16071347',
    };
    this.onVideoClick = this.onVideoClick.bind(this);
  }

  onVideoClick(event, url) {
    event.preventDefault();
    this.setState({
      url: url,
    });
  }
  render() {
    return (
      <Container className="full-width-transparent clearfix content-inner">
        <Row>
          <h1>Videos</h1>
        </Row>
        <Row>
          <ResponsivePlayer url={this.state.url} />
        </Row>
        <Row>
          <div id="player_playlist">
            <ul id="player_playlist_list">
              <li class="">
                <a
                  href=""
                  onClick={(event) =>
                    this.onVideoClick(event, 'https://vimeo.com/80729924')
                  }
                  title="Field Dressing"
                >
                  <span class="shade"></span>
                  <span class="border"></span>
                  <img
                    src="https://i.vimeocdn.com/video/456713753-df18f37334ef53385f592441d019de6d075b1971617f498ed7f336c344ac9482-d_100x75"
                    alt=""
                  />
                  <span class="title">Field Dressing</span>
                  <span class="byline">
                    from <b>bigcountrystudios</b>{' '}
                    <time datetime="2013-12-01 12:39:12">8 years ago</time>
                  </span>
                  <span class="desc">
                    Video 1 - Properly Field Dressing your trophyh...
                  </span>
                  <span class="duration">12:01</span>
                </a>
              </li>
              <li class="">
                <a
                  href="#"
                  onClick={(event) =>
                    this.onVideoClick(event, 'https://vimeo.com/80729925')
                  }
                  title="Skinning"
                >
                  <span class="shade"></span>
                  <span class="border"></span>
                  <img
                    src="https://i.vimeocdn.com/video/456725657-fbfeb91cf73d98f10c4f7c6ddf4be3e94238ae9f10505c9c20d30c8befa37c08-d_100x75"
                    alt=""
                  />
                  <span class="title">Skinning</span>
                  <span class="byline">
                    from <b>bigcountrystudios</b>{' '}
                    <time datetime="2013-12-01 12:39:12">8 years ago</time>
                  </span>
                  <span class="desc">Video 2 - Skinning</span>
                  <span class="duration">20:47</span>
                </a>
              </li>
              <li class="">
                <a
                  href="https://vimeo.com/80729926"
                  onClick={(event) =>
                    this.onVideoClick(event, 'https://vimeo.com/80729926')
                  }
                  title="Caping"
                >
                  <span class="shade"></span>
                  <span class="border"></span>
                  <img
                    src="https://i.vimeocdn.com/video/456714759-7322aed5d28faa832169f68428340840b519b2263d4a7ca5869cd7ee85568ee6-d_100x75"
                    alt=""
                  />
                  <span class="title">Caping</span>
                  <span class="byline">
                    from <b>bigcountrystudios</b>{' '}
                    <time datetime="2013-12-01 12:39:12">8 years ago</time>
                  </span>
                  <span class="desc">Video 3 - Caping</span>
                  <span class="duration">26:07</span>
                </a>
              </li>
              <li class="">
                <a
                  href="https://vimeo.com/80729927"
                  onClick={(event) =>
                    this.onVideoClick(event, 'https://vimeo.com/80729927')
                  }
                  title="Freezer Prep"
                >
                  <span class="shade"></span>
                  <span class="border"></span>
                  <img
                    src="https://i.vimeocdn.com/video/456713720-788217f083afa7699c3063a1df0ea98122bca26c7695c38557b4b3042cfd29df-d_100x75"
                    alt=""
                  />
                  <span class="title">Freezer Prep</span>
                  <span class="byline">
                    from <b>bigcountrystudios</b>{' '}
                    <time datetime="2013-12-01 12:39:12">8 years ago</time>
                  </span>
                  <span class="desc">
                    Video 4 - Freezer prep http://www.wildlifeartcr...
                  </span>
                  <span class="duration">9:02</span>
                </a>
              </li>
              <li class="">
                <a
                  href="https://vimeo.com/16071347"
                  onClick={(event) =>
                    this.onVideoClick(event, 'https://vimeo.com/16071347')
                  }
                  title="Field Care Advice - Part 1"
                >
                  <span class="shade"></span>
                  <span class="border"></span>
                  <img
                    src="https://i.vimeocdn.com/video/97859843-a5696bad38011bac121f58eae51107f5c060c939ee896d855b07d57f8c50dd8a-d_100x75"
                    alt=""
                  />
                  <span class="title">Field Care Advice - Part 1</span>
                  <span class="byline">
                    from <b>bigcountrystudios</b>{' '}
                    <time datetime="2010-10-21 17:00:19">11 years ago</time>
                  </span>
                  <span class="desc">
                    Taxidermist Tony Breedlove speaks about Field Care...
                  </span>
                  <span class="duration">2:48</span>
                </a>
              </li>
              <li class="">
                <a
                  href="https://vimeo.com/16071520"
                  onClick={(event) =>
                    this.onVideoClick(event, 'https://vimeo.com/16071520')
                  }
                  title="Field Care Advice - Part 2"
                >
                  <span class="shade"></span>
                  <span class="border"></span>
                  <img
                    src="https://i.vimeocdn.com/video/97862632-230e783dcc921ac9d07693a52040568b37ae1605ffd5a370d3e5284e72edb7ed-d_100x75"
                    alt=""
                  />
                  <span class="title">Field Care Advice - Part 2</span>
                  <span class="byline">
                    from <b>bigcountrystudios</b>{' '}
                    <time datetime="2010-10-21 17:05:25">11 years ago</time>
                  </span>
                  <span class="desc">
                    Taxidermist Tony Breedlove speaks about Field Care...
                  </span>
                  <span class="duration">9:09</span>
                </a>
              </li>
              <li class="">
                <a
                  href="https://vimeo.com/16072108"
                  onClick={(event) =>
                    this.onVideoClick(event, 'https://vimeo.com/16072108')
                  }
                  title="Wildlife Art Creations"
                >
                  <span class="shade"></span>
                  <span class="border"></span>
                  <img
                    src="https://i.vimeocdn.com/video/97863714-ac5412d879c04e2adc7d80f5f471c594744e514984a7f567ba1968b2347c60b9-d_100x75"
                    alt=""
                  />
                  <span class="title">Wildlife Art Creations</span>
                  <span class="byline">
                    from <b>bigcountrystudios</b>{' '}
                    <time datetime="2010-10-21 17:23:58">11 years ago</time>
                  </span>
                  <span class="desc">
                    Taxidermist Tony Breedlove speaks about Taxidermis...
                  </span>
                  <span class="duration">1:33</span>
                </a>
              </li>
              <li class="">
                <a
                  href="https://vimeo.com/60049521"
                  onClick={(event) =>
                    this.onVideoClick(event, 'https://vimeo.com/60049521')
                  }
                  title="Insect Damage Repair"
                >
                  <span class="shade"></span>
                  <span class="border"></span>
                  <img
                    src="https://i.vimeocdn.com/video/417533893-19119b54cf6ce2244cfa76fc966ab90168a0527d58cfe2c478853d14115380f0-d_100x75"
                    alt=""
                  />
                  <span class="title">Insect Damage Repair</span>
                  <span class="byline">
                    from <b>bigcountrystudios</b>{' '}
                    <time datetime="2013-02-19 22:00:25">9 years ago</time>
                  </span>
                  <span class="desc">
                    Taxidermist Tony Breedlove talks about repairing r...
                  </span>
                  <span class="duration">20:49</span>
                </a>
              </li>
              <li class=" selected">
                <a
                  href="https://vimeo.com/60049522"
                  onClick={(event) =>
                    this.onVideoClick(event, 'https://vimeo.com/60049522')
                  }
                  title="Wildlife Art Creations Gallery Slideshow"
                >
                  <span class="shade"></span>
                  <span class="border"></span>
                  <img
                    src="https://i.vimeocdn.com/video/417535484-c5e9dc1d51e28c41632a312d04ac6608ebc7074e7ec7387caffb4219530c7969-d_100x75"
                    alt=""
                  />
                  <span class="title">Wildlife Art Creations Gallery...</span>
                  <span class="byline">
                    from <b>bigcountrystudios</b>{' '}
                    <time datetime="2013-02-19 22:00:25">9 years ago</time>
                  </span>
                  <span class="desc"></span>
                  <span class="duration">21:00</span>
                </a>
              </li>
            </ul>
          </div>
        </Row>
      </Container>
    );
  }
}
export default Videos;
